<!-- 模块说明 -->
<template>
  <div class="container">
    <div v-if="typeShow == 1" class="information">
      <div class="user">
        <span>客户名称: {{ detailData.terName }}</span>
        <span>手机号: {{ detailData.terPhone }}</span>
      </div>
      <div class="flex">
        <div class="view">
          <div class="titme">
            <span>{{ minute(creditData.videoTime) || 0 }}</span
            >分钟
          </div>
          <div class="text">短视频剩余时长</div>
        </div>
        <div class="view">
          <div class="titme">
            <span>{{ minute(creditData.creditTime) || 0 }}</span
            >分钟
          </div>
          <div class="text">无人直播剩余时长</div>
        </div>
        <div class="view">
          <div class="titme">
            <span>{{ minute(creditData.liveRPTime) || 0 }}</span
            >分钟
          </div>
          <div class="text">真人驱动直播剩余时长</div>
        </div>
      </div>
    </div>
    <div class="tablist">
      <div v-if="typeShow == 1" class="title">充值记录</div>
      <div class="flex search">
        <el-date-picker
          class="waicengPhone"
          v-model="searchEle.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        >
        </el-date-picker>
        <el-select
          v-model="searchEle.durationType"
          class="waicengPhone"
          clearable
          placeholder="请选择产品类型"
        >
          <el-option
            v-for="item in DURATIONTYPEENUM"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button @click="(currentPage = 1), requestGudgment()" type="primary">
          查询
        </el-button>
      </div>
      <div class="total">
        合计：<span
          >短视频累计{{ typeShow == 3 ? "消费" : "充值" }}
          {{
            minute(
              typeShow == 3
                ? tableData2.totalVideoTime
                : tableData.totalVideoTime
            )
          }}
          分钟</span
        >
        <span
          >无人直播累计{{ typeShow == 3 ? "消费" : "充值" }}
          {{
            minute(
              typeShow == 3
                ? tableData2.totalCreditTime
                : tableData.totalCreditTime
            )
          }}
          分钟</span
        >
        <span
          >真人驱动直播累计{{ typeShow == 3 ? "消费" : "充值" }}
          {{
            minute(
              typeShow == 3
                ? tableData2.totalLiveRPTime
                : tableData.totalLiveRPTime
            )
          }}
          分钟</span
        >
      </div>
      <el-table
        v-if="(typeShow == 1 || typeShow == 2 || typeShow == 4) && !loading"
        :data="tableData.recordDto.records"
        stripe
        class="HNMR"
      >
        <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column prop="phone" label="充值时长">
          <template slot-scope="scope">
            {{
              minute(
                typeShow == 1 ? scope.row.timeSecond : scope.row.remainingTime
              )
            }}分钟
          </template>
        </el-table-column>
        <el-table-column v-if="typeShow == 1" prop="videoTime" label="有效期">
          <template slot-scope="scope">
            {{ scope.row.validMonth }}月
            <template v-if="scope.row.isExpires"
              >(<span style="color: brown">失效</span>)</template
            >
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="产品类型">
          <template slot-scope="scope">
            {{ filterReturn(DURATIONTYPEENUM, scope.row.durationType) }}
          </template>
        </el-table-column>
        <el-table-column prop="videoTime" label="充值时间">
          <template slot-scope="scope">
            {{ Dateformat(scope.row.gmtCreate, "YYYY-MM-DD HH:mm") || "--" }}
          </template>
        </el-table-column>
        <el-table-column label="操作人">
          <template slot-scope="scope">
            {{ typeShow == 1 ? scope.row.createUserName : scope.row.username }}
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="typeShow == 3 && !loading"
        :data="tableData2.expenseCalendarDto.records"
        stripe
        class="HNMR"
      >
        <el-table-column prop="terName" label="客户名称"></el-table-column>
        <el-table-column prop="terPhone" label="手机号"></el-table-column>
        <el-table-column prop="phone" label="消费时长">
          <template slot-scope="scope">
            {{ minute(scope.row.timeSecond) }}分钟
          </template>
        </el-table-column>
        <el-table-column prop="videoTime" label="产品类型">
          <template slot-scope="scope">
            {{ filterReturn(DURATIONTYPEENUM, scope.row.durationType) }}
          </template>
        </el-table-column>
        <el-table-column prop="videoTime" label="有效期">
          <template slot-scope="scope">
            {{ scope.row.validMonth }}月
            <template v-if="scope.row.expirationTime < Date.parse(new Date())"
              >(<span style="color: brown">失效</span>)</template
            >
          </template>
        </el-table-column>
        <el-table-column prop="videoTime" width="180" label="操作时间">
          <template slot-scope="scope">
            {{ Dateformat(scope.row.gmtCreate, "YYYY-MM-DD HH:mm") || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="username" label="操作人"></el-table-column>
      </el-table>
      <el-row>
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="pageSize"
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="totalNum"
          @current-change="handelCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    typeShow: {
      type: Number,
      default: 1, //1详情，2充值记录，3消费记录
    },
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      searchEle: {},
      DURATIONTYPEENUM: [],
      tableData: {
        recordDto: {
          records: [],
        },
      },
      tableData2: {
        expenseCalendarDto: {
          records: [],
        },
      },
      totalNum: 0,
      currentPage: 1,
      pageSize: 10,
      creditData: {},
      loading: false,
    };
  },
  watch: {},
  async created() {
    let arrTable = await this.getMultipleTable(["DURATIONTYPEENUM"]);
    this.DURATIONTYPEENUM = arrTable.DURATIONTYPEENUM;
  },
  methods: {
    requestGudgment() {
      this.loading = true;
      switch (this.typeShow) {
        case 1:
          this.getRecordList();
          break;
        case 2:
          this.getRechargeRecord();
          break;
        case 3:
          this.getExpenseCalendar();
          break;
        case 4:
          this.getRechargeAgentRecord();
          break;
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.requestGudgment();
    },
    //翻页
    handelCurrentChange(page) {
      this.currentPage = page;
      this.requestGudgment();
    },
    //获取代理客户/充值记录
    getRechargeAgentRecord() {
      let params = {
        ...this.returnParams(),
        amId: this.userInfo.amId,
      };
      this.http
        .get("/agentAccount/details/getRechargeAgentRecord", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.totalNum = this.tableData.recordDto.total;
          this.loading = false;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取 客户 管理充值记录列表
    getRecordList() {
      let params = {
        ...this.returnParams(),
        terId: this.detailData.terId,
      };
      this.http
        .get("/terminal/customer/getTerCustomerRechargeRecordList", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.totalNum = this.tableData.recordDto.total;
          this.loading = false;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取 代理 管理充值记录列表
    getRechargeRecord() {
      let params = {
        ...this.returnParams(),
        amId: this.detailData.amId,
      };
      this.http
        .get("/agencyManagement/getRechargeRecord", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.totalNum = this.tableData.recordDto.total;
          this.loading = false;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取 消费 记录列表
    getExpenseCalendar() {
      let params = {
        ...this.returnParams(),
        amId: this.detailData.amId,
      };
      this.http
        .get("/agencyManagement/getExpenseCalendar", params)
        .then((res) => {
          this.tableData2 = res.data.data;
          this.totalNum = this.tableData2.expenseCalendarDto.total;
          this.loading = false;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取客户详情/充值记录
    getRechargeRecordDetailsList() {
      let params = {
        ...this.returnParams(),
        terId: this.detailData.terId,
      };
      this.http
        .get("/agentAccount/details/getRechargeRecordDetailsList", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.totalNum = this.tableData.recordDto.total;
          this.loading = false;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选项
    returnParams() {
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      params = Object.assign({}, this.searchEle, params);
      if (params.time) {
        params.startTime = params.time[0];
        params.endTime = params.time[1];
        delete params.time;
      }
      return params;
    },
    //获取各项剩余时长
    getAllremain() {
      this.http
        .get("/open/company/all/remain/query/" + this.detailData.terPhone)
        .then((res) => {
          if (res.data.code == 200) {
            this.creditData = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    userInfo() {
      return JSON.parse(this.getCacheData("userInfo"));
    },
  },
};
</script>
<style lang="less" scoped>
.tablist {
  .total {
    font-size: 14px;
    color: #ea68a2;
    span {
      padding-right: 20px;
    }
  }
  .search {
    padding: 20px 0;
    justify-content: flex-start;
  }
  .title {
    padding: 20px 0;
    font-size: 16px;
    color: white;
    border-bottom: 1px solid #1a1f58;
  }
}
.information {
  .flex {
    .view {
      padding: 12px 60px 15px;
      border-radius: 4px;
      color: white;
      font-size: 14px;
      margin-right: 30px;
      text-align: center;
      max-width: 220px;
      span {
        font-size: 28px;
        padding-right: 10px;
      }
    }
    .view:nth-child(1) {
      background-color: #0088da;
    }
    .view:nth-child(2) {
      background-color: #544bed;
    }
    .view:nth-child(3) {
      background-color: #892bf8;
    }
    justify-content: flex-start;
  }
  .user {
    font-size: 18px;
    color: white;
    span {
      padding-right: 100px;
    }
    margin-bottom: 25px;
  }
  margin-bottom: 10px;
}
</style>
