<!-- 模块说明 -->
<template>
  <div class="container">
    <div class="view">
      <div class="flex">
        <div class="title">账号总览</div>
        <el-button @click="recycleRights" type="primary">
          过期权益回收
        </el-button>
      </div>
      <div class="flex" style="margin-top: 20px; justify-content: flex-start">
        <div class="overview">
          <div
            class="top"
            style="background: linear-gradient(0deg, #3fbbfe 0%, #a541ff 100%)"
          >
            {{ minute(creditData.videoTime) || 0 }}
            <span class="bezhu">分钟</span>
          </div>
          <div class="bottom" style="background: #4a25ac">
            短视频制作会员剩余时长
          </div>
        </div>
        <div class="overview">
          <div
            class="top"
            style="background: linear-gradient(0deg, #01e9bd 0%, #007cde 100%)"
          >
            {{ minute(creditData.creditTime) || 0 }}
            <span class="bezhu">分钟</span>
          </div>
          <div class="bottom" style="background: #1c7192">
            2.5D无人AI直播会员剩余时长
          </div>
        </div>
        <div class="overview">
          <div
            class="top"
            style="background: linear-gradient(0deg, #09bbfe 0%, #5a42ec 100%)"
          >
            {{ minute(creditData.liveRPTime) || 0 }}
            <span class="bezhu">分钟</span>
          </div>
          <div class="bottom" style="background: #2d40a7">
            3D真人驱动直播会员剩余时长
          </div>
        </div>
      </div>
    </div>
    <div class="view" style="margin-top: 40px">
      <div class="flex" style="flex-wrap: wrap">
        <div class="title">客户详情</div>
        <div class="flex">
          <el-date-picker
            class="waicengPhone"
            v-model="searchEle.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
          <el-select
            v-model="searchEle.terType"
            class="waicengPhone"
            clearable
            placeholder="请选择客户类型"
          >
            <el-option
              v-for="item in terTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            class="waicengPhone"
            v-model="searchEle.amPhone"
            placeholder="请输入代理手机号"
            clearable
            maxlength="11"
          ></el-input>
          <el-input
            class="waicengPhone"
            v-model="searchEle.terPhone"
            placeholder="请输入客户手机号"
            clearable
            maxlength="11"
          ></el-input>
          <el-button type="primary" @click="(currentPage = 1), getAll()"
            >查询</el-button
          >
          <el-button @click="addAgent(true)" type="primary">
            添加终端客户
          </el-button>
          <el-button @click="exportOn" type="primary" plain>
            导出数据
          </el-button>
        </div>
      </div>
      <el-table :data="tableData" stripe class="HNMR" max-height="370">
        <el-table-column prop="terName" label="客户名称"> </el-table-column>
        <el-table-column prop="terPhone" label="手机号"> </el-table-column>
        <el-table-column label="客户来源">
          <template slot-scope="scope">
            {{ filterReturn(terTypeList, scope.row.terType) }}
          </template>
        </el-table-column>
        <el-table-column label="所属代理">
          <template slot-scope="scope">
            {{ scope.row.amName || "--" }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ Dateformat(scope.row.gmtCreate, "YYYY-MM-DD HH:mm") || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建人">
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="addAgent(false, scope.row)" type="text"
              >编辑</el-button
            >
            <el-button @click="openPopup(scope.row)" type="text"
              >添加时长</el-button
            >
            <el-button @click="openRecord(scope.row)" type="text"
              >充值记录</el-button
            >
          </template>
        </el-table-column>
        <!-- <template slot="empty">
          <div class="nodata">
            <img src="../../images/back/nodata.png" alt="" />
            <div>暂无数据</div>
          </div>
        </template> -->
      </el-table>
      <el-row>
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="pageSize"
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="totalNum"
          @current-change="handelCurrentChange"
          @size-change="handleSizeChange"
          :page-sizes="[5, 10, 15, 20]"
        >
        </el-pagination>
      </el-row>
    </div>
    <el-dialog
      class="commonDialog"
      :title="(addAgentType ? '添加' : '编辑') + '终端客户'"
      :visible.sync="addAgentPopup"
      width="500px"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="客户名称"
          prop="terName"
          :rules="{
            required: true,
            message: '请输入客户名称',
            trigger: 'blur',
          }"
        >
          <el-input
            class="waicengPhone"
            v-model="ruleForm.terName"
            placeholder="请输入客户名称"
            clearable
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="terPhone"
          :rules="{
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          }"
        >
          <el-input
            class="waicengPhone"
            v-model="ruleForm.terPhone"
            placeholder="请输入手机号"
            clearable
            :disabled="!addAgentType"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户类型">
          <el-radio-group v-model="ruleForm.terType" :disabled="!addAgentType">
            <el-radio label="DIRENT_SELLING">直销</el-radio>
            <el-radio label="AGENCY">代理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="所属代理"
          prop="amId"
          :rules="{
            required: true,
            message: '请选择所属代理',
            trigger: 'change',
          }"
          v-if="ruleForm.terType == 'AGENCY'"
        >
          <el-select
            v-model="ruleForm.amId"
            class="waicengPhone"
            clearable
            placeholder="请选择所属代理"
            :disabled="!addAgentType"
          >
            <el-option
              v-for="item in AgencyList"
              :key="item.amId"
              :label="item.name"
              :value="item.amId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-top: 50px">
          <el-button type="primary" plain @click="addAgentPopup = false"
            >取消</el-button
          >
          <el-button type="primary" @click="resetForm('ruleForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      class="commonDialog"
      title="添加会员时长"
      :visible.sync="addDuration"
      width="650px"
    >
      <div class="userInfo flex">
        <div>客户名称: {{ addDurationData.row.terName }}</div>
        <div>
          客户类型: {{ filterReturn(terTypeList, addDurationData.row.terType) }}
        </div>
        <div>手机号: {{ addDurationData.row.terPhone }}</div>
        <div>所属代理: {{ addDurationData.row.amName || "--" }}</div>
      </div>
      <div class="radio-group">
        <el-radio-group v-model="addDurationData.type">
          <el-radio
            v-for="item in addDurationData.list"
            :key="item.id"
            :label="item.type"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
        <div class="flex" style="width: 100%; justify-content: flex-start">
          <div class="flex">
            <el-input
              class="waicengPhone"
              v-model="addDurationData.time"
              placeholder="请输入时长"
              clearable
              style="width: 75% !important"
              v-Int
              @input="limitation"
            ></el-input>
            分钟
          </div>
          <div class="flex" style="margin-left: 40px">
            有效期
            <el-input-number
              v-model="addDurationData.expirationDate"
              :min="1"
              :max="18"
              size="small"
              style="width: 120px; margin: 0 10px"
            ></el-input-number>
            个月
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="addDuration = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="submitTo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="commonDialog"
      title="查看详情"
      :visible.sync="recordPopup.show"
      width="900px"
      @close="recordPopupDown"
    >
      <recordPopup
        ref="record"
        :typeShow="1"
        :detailData="recordPopup.res"
      ></recordPopup>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import recordPopup from "../../components/recordPopup.vue";
export default {
  components: { recordPopup },
  props: [],
  data() {
    return {
      searchEle: {},
      tableData: [],
      totalNum: 0,
      currentPage: 1,
      pageSize: 5,
      creditData: {},
      addDuration: false,
      addDurationData: {
        type: "VIDEO_TIME",
        time: null,
        row: {},
        expirationDate: 1,
        list: [
          {
            name: "短视频时长",
            id: 1,
            type: "VIDEO_TIME",
          },
          {
            name: "无人直播时长",
            id: 2,
            type: "CREDIT_TIME",
          },
          {
            name: "真人驱动直播时长",
            id: 3,
            type: "LIVE_RP_TIME",
          },
        ],
      },
      ruleForm: {},
      addAgentPopup: false,
      addAgentType: false,
      terTypeList: [
        {
          value: "DIRENT_SELLING",
          label: "直销",
        },
        {
          value: "AGENCY",
          label: "代理",
        },
      ],
      AgencyList: [],
      recordPopup: {
        show: false,
        res: {},
      },
    };
  },
  watch: {},
  created() {
    this.getAgencyManagementEasyList();
    this.getCredit();
    this.getAll();
  },
  methods: {
    limitation(){
      if(this.addDurationData.time>5000){
        this.$message('客户单次添加时长不超过5000分钟')
        this.addDurationData.time = null;
      }
    },
    //打开充值记录弹窗并赋值
    openRecord(row) {
      this.recordPopup.res = row;
      this.recordPopup.show = true;
      setTimeout(() => {
        this.$refs.record.getAllremain();
        this.$refs.record.getRecordList();
      }, 200);
    },
    //关闭充值记录弹窗
    recordPopupDown() {
      this.$refs.record.searchEle = {};
    },
    //添加/编辑代理客户提交
    resetForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          let params = this.ruleForm;
          let url = this.addAgentType
            ? "addTerminalCustomer"
            : "updateTerminalCustomer";
          this.http
            .post("/terminal/customer/" + url, params)
            .then((res) => {
              if (res.data.success) {
                this.$message.success(
                  this.addAgentType ? "添加成功" : "编辑成功"
                );
                this.addAgentPopup = false;
                this.getAll();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    //打开添加/编辑代理客户弹窗
    addAgent(type, row) {
      this.addAgentType = type;
      if (!type) {
        this.ruleForm = {
          terType: row.terType,
          terName: row.terName,
          terPhone: row.terPhone,
          terId: row.terId,
          amId: row.amId,
        };
      } else {
        this.ruleForm = {};
      }
      this.addAgentPopup = true;
    },
    //弹窗提交
    submitTo() {
      let resData = this.addDurationData;
      if (resData.time && resData.expirationDate) {
        let params = {
          durationType: resData.type,
          terId: resData.row.terId,
          time: +resData.time * 60,
          expirationDate: +resData.expirationDate,
        };
        this.http
          .post("/terminal/customer/addDuration", params)
          .then((res) => {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.addDuration = false;
            resData.time = null;
            resData.expirationDate = 1;
          })
          .catch(() => {
            resData.time = null;
            resData.expirationDate = 1;
          });
      } else {
        this.$message.error("请完善信息!");
        return;
      }
    },
    //打开弹窗
    openPopup(row) {
      this.addDurationData.row = row;
      this.addDuration = true;
    },
    //权益回收
    recycleRights() {
      this.$confirm(
        "确定要对此账号下的所有过期权益进行回收吗？此操作不可逆，请慎重操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.http.post("/open/company/recycleRights").then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "回收成功!",
              });
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {});
    },
    //获取列表数据
    getAll() {
      this.http
        .get("/terminal/customer/getTerminalCustomerList", this.returnParams())
        .then((res) => {
          this.tableData = res.data.data;
          this.totalNum = res.data.totalRecords;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //导出数据
    async exportOn() {
      let requestData = await this.returnUrl();
      axios
        .get(
          requestData.config.baseURL +
            "/terminal/customer/exportRechargeRecord",
          {
            timeout: 6000000,
            headers: requestData.config.headers,
            responseType: "blob", //重要
            params: this.returnParams(),
          }
        )
        .then((res) => {
          console.log(res);
          this.toExport(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选项
    returnParams() {
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      params = Object.assign({}, this.searchEle, params);
      if (params.time) {
        params.gmtCreateStartDate = params.time[0];
        params.gmtCreateEndDate = params.time[1];
        delete params.time;
      }
      return params;
    },
    //获取代理下拉
    getAgencyManagementEasyList() {
      this.http
        .get("/agencyManagement/getAgencyManagementEasyList")
        .then((res) => {
          this.AgencyList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getAll();
    },
    //翻页
    handelCurrentChange(page) {
      this.currentPage = page;
      this.getAll();
    },
    //获取总数据
    getCredit() {
      this.http
        .get("/open/company/credit")
        .then((res) => {
          console.log(res.data.data);
          this.creditData = res.data.data;
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.radio-group {
  .flex {
    width: 215px;
    margin-top: 30px;
    color: white;
    font-size: 14px;
  }
  padding: 20px 0;
}
.userInfo {
  font-size: 16px;
  color: white;
  justify-content: flex-start;
  flex-wrap: wrap;
  div {
    margin: 0 40px 20px 0;
  }
  border-bottom: 1px solid #1a1f58;
}
.el-radio {
  color: #d3d3d3;
}
/deep/.el-form-item__label {
  color: white !important;
}
.addDuration {
  .phone {
    .el-input {
      width: 80%;
    }
    span {
      color: #ea6649;
    }
    font-size: 16px;
  }
  padding: 20px;
}

.container {
  .overview {
    .top {
      .use {
        color: #ea6649;
      }
      .bezhu {
        font-size: 18px;
        font-weight: 400;
      }
      font-weight: bold;
      height: 140px;
      line-height: 140px;
      text-align: center;
      font-size: 32px;
      color: white;
    }
    .bottom {
      font-size: 18px;
      color: white;
      height: 52px;
      text-align: center;
      line-height: 52px;
    }
    width: 400px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 60px;
  }
  .view {
    .title {
      color: white;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    margin-top: 20px;
  }
  // width: 1400px;
  margin: auto;
}
</style>
